<template lang="pug">
.common-create-stepper-edit-dialog
  v-dialog(
    max-width="650"
    persistent
    v-model="isOpen"
  )
    v-form(
      ref="createEditForm"
      v-model="valid"
      @submit.prevent="submit"
    )
      v-card
        v-card-text.pa-0.ma-0
          slot(
            name="stepper"
            v-bind:save="submit"
            v-bind:rules="rules"
            v-bind:valid="valid"
          )

        v-card-actions( right ).bg-gray-100
          slot(
            name="action"
            v-bind:valid="valid"
          )
</template>

<script>
import formValidations from '@/helpers/formValidations'

export default {
  name: 'CreateStoreStepperEditDialog',

  props: {
    isOpen: {
      required: true,
      type: Boolean,
      default: false
    },

    title: {
      type: String,
      required: false,
      default: 'Add'
    },

    saveAction: {
      type: String,
      required: false,
      default: 'Save'
    }
  },

  setup(props, { emit }) {
    const { valid, rules } = formValidations()
    const submit = () => emit('submit-form')

    return {
      valid,
      rules,
      submit
    }
  }
}
</script>

<style scoped>
::v-deep .v-stepper__wrapper { padding: 5px }
</style>