<template lang="pug">
.candidate-create-edit-dialog
  CreateStoreStepperEditDialog(
    title="Setup Candidate"
    :isOpen="isOpen"
    @close="$emit('close')"
    @submit-form="saveCandidate"
  )
    template(
      #stepper="{ rules, valid }"
    )
      v-stepper( v-model="step" elevation="0" )
        v-stepper-header
          v-stepper-step(
            :complete="step > 1"
            step="1"
            :error-icon="'mdi-alert-circle'"
            :rules="[() => isValid('candidate', 1, candidateModel.candidate)]"
            :color="stepColor(1, isValid('candidate', 1, candidateModel.candidate))"
          )
            div( @click="step = 1" class="cursor-pointer hover:underline" )
              | Setup Candidate

          v-divider

          v-stepper-step(
            :complete="step > 2"
            step="2"
            :error-icon="'mdi-alert-circle'"
            :rules="[() => isValid('campaign', 2)]"
            :color="stepColor(2, isValid('campaign', 2))"
          )
            div( @click="step = 2" class="cursor-pointer hover:underline" )
              | Setup Campaign

          v-divider

          v-stepper-step(
            :complete="step > 3 && valid"
            step="3"
            :color="stepColor(3, valid && (isOrganization ? isValid('organization', 3) : true ))"
          )
            div( @click="step = 3" class="cursor-pointer hover:underline" )
              | {{ isOrganization ? 'Treasurer & Committee' : 'Treasurer' }}

        v-stepper-items
          v-stepper-content( step="1" )
            h5 Associated to Organization Types:
            v-divider.mb-5

            OrgTypeSelect.mb-5(
              v-model="candidateModel.candidate.organizationTypeIds"
            )

            h5 Candidate info
            v-divider.mb-5

            CandidateSearchAndForm(
              :rules="rules"
            )

          //- Campaign: step 2
          v-stepper-content( step="2" )
            .d-flex.justify-content-between.gap-8
              v-select(
                outlined
                dense
                class="w-1/2"
                label="Chamber"
                v-model="candidateModel.campaign.chamber"
                :items="chambers"
              )

              v-text-field(
                outlined
                dense
                class="w-1/2"
                label="District Number"
                v-model="candidateModel.campaign.districtNumber"
                :rules="[rules.isNumeric]"
              )

            .d-flex.justify-content-between.gap-8
              v-select(
                outlined
                dense
                class="w-1/2"
                label="Support Level"
                v-model="candidateModel.campaign.supportLevel"
                :items="supportLevels"
              )

              v-select(
                outlined
                dense
                class="w-1/2"
                label="Party"
                v-model="candidateModel.campaign.party"
                :items="parties"
              )

            .d-flex.justify-content-between.gap-8
              div( class="w-1/2 mb-6" )
                AmountFormItem(
                  label="Campaign Budget"
                  v-model="candidateModel.campaign.budget"
                  :rules="rules"
                )
              div( class="w-1/2" )

            .d-flex.justify-content-between.gap-8
              DatePickerDialog(
                label="Primary Election Date"
                v-model="candidateModel.campaign.primaryElectionDate"
              )

              DatePickerDialog(
                label="General Election Date"
                v-model="candidateModel.campaign.generalElectionDate"
              )

          //- Treasurer: step 3
          v-stepper-content( step="3" )
            h5 Add Treasurer
            v-divider.mb-5

            .d-flex.justify-content-between.gap-8
              v-text-field(
                outlined
                dense
                label="First Name"
                v-model="candidateModel.candidate.treasurerFirstName"
                :rules="[rules.required]"
              )

              v-text-field(
                outlined
                dense
                label="Last Name"
                v-model="candidateModel.candidate.treasurerLastName"
                :rules="[rules.required]"
              )

            AddressForm(
              :rules="rules"
              :clone="candidateModel.candidate"
              addressKey="treasurerAddress"
              skip-validation
            )

            template( v-if="isOrganization" )
              h5.mt-5 Add Committee
              v-divider.mb-5

              .d-flex.justify-content-between.gap-8
                v-text-field(
                  outlined
                  dense
                  label="Committee Name"
                  v-model="candidateModel.committee.name"
                  :rules="[rules.required]"
                )

              AddressForm(
                :rules="rules"
                :clone="candidateModel.committee"
                addressKey="address"
                skip-validation
              )
    template( #action="{ valid }" )
      .d-flex.justify-space-between.w-full
        v-btn(
          depressed
          @click="$emit('close')"
        ) Cancel
        .d-flex
          div.mr-2
            v-btn(
              :disabled="step === 1"
              depressed
              color="secondary"
              type="button"
              @click="step--"
            ) Back
          div
            v-btn(
              v-if="step !== 3"
              :disabled="step === 3"
              depressed
              color="primary"
              type="button"
              @click="step++"
            ) Next
          div
            v-btn(
              v-if="step === 3"
              :disabled="!valid || !isValid('candidate', 2) || !isValid('campaign', 2)"
              depressed
              color="primary"
              type="submit"
            ) Add

</template>

<script>
import { isOrganization } from '@/helpers/identityHelper'
import { chambers, supportLevels, parties } from '@/helpers/campaignData'
import AddressForm from '@/components/Address.Form'
import { ref, onUnmounted } from '@vue/composition-api';
import CreateStoreStepperEditDialog from '@/components/Dialogs/CreateEdit.Store.Stepper.Dialog'
import StateList from '@/components/StateList'
import DatePickerDialog from '@/components/DatePicker.Dialog'
import OrgTypeSelect from '@/views/organization-types/OrgType.Select.Dialog'
import AmountFormItem from '@/components/Amount.FormItem'
import CandidateSearchAndForm from '@/views/candidates/_partials/Candidate.SearchForm'
import useCandidateStore from '@/store/custom/candidate.store'

export default {
  name: 'CandidateCreateDialog',

  components: {
    AddressForm,
    StateList,
    DatePickerDialog,
    CreateStoreStepperEditDialog,
    OrgTypeSelect,
    AmountFormItem,
    CandidateSearchAndForm
  },

  props: {
    title: {
      type: String,
      default: 'Add Candidate'
    },

    isOpen: Boolean
  },

  setup(props, { emit, root: { $FeathersVuex, $store, $snackSuccess, $snackError } }) {
    const step = ref(1);
    const { init, reset } = useCandidateStore()
    const candidateModel = init($FeathersVuex.api)

    const reauth = async () => {
      $store.dispatch('auth/authenticate', {
        accessToken: $store.state.auth.accessToken,
        strategy: 'jwt'
      })
    }

    const saveCandidate = async () => {
      try {
        const {
          candidate,
          committee,
          campaign
        } = candidateModel

        // 1. add organization first as we associate with candidate
        const { id: committeeId } = await committee.save()
        candidate.committeeId = committeeId

        // 2. associate to each other
        const { id: candidateId } = await candidate.save()
        campaign.candidateId = candidateId

        const { id: campaignId } = await campaign.save()
        await candidate.patch({ data: { campaignId } })

        /*  we are re-authing here to reapply the limitById casl limiter
            to the backend query */
        await reauth()

        $snackSuccess('Candidate Created')
        emit('close')
      } catch (e) {
        $snackError('Error adding the candidate', e.message)
      }
    }

    const isValid = (model, selectStep) => {
      const currentStep = step.value;

      if (currentStep > selectStep) {
        switch (model) {
          case 'candidate': {
            const { candidate } = candidateModel
            return !!candidate.firstName && !!candidate.lastName && !!candidate.organizationTypeIds?.length;
          }
          case 'campaign': {
            const { campaign } = candidateModel
            return !!campaign.districtNumber;
          }
          case 'organization': {
            const { committee } = candidateModel
            return !!committee.name;
          }
          default:
            console.log('Invalid model');
            return false;
        }
      }
      return true
    }

    const stepColor = (selectStep, isValid) => {
      const currentStep = step.value;

      if (currentStep > selectStep)
        return isValid ? 'success' : 'error'
      else
        return 'primary'
    }

    onUnmounted(() => reset())

    return {
      isValid,
      stepColor,
      saveCandidate,

      isOrganization,
      step,
      chambers,
      supportLevels,
      parties,

      candidateModel
    }
  }
}
</script>