var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"candidate-list"},[_c('div',{staticClass:"d-flex justify-between align-center"},[_c('h3',{class:{ 'mb-12' : _vm.isCommittee }},[_vm._v("Candidates")]),_c('div',[(_vm.$can('create', 'candidates') && !_vm.isCommittee)?_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":function($event){_vm.isCandidateCreateEditOpen = true}}},[_vm._v("+ New Candidate")]):_vm._e()],1)]),(!_vm.isCommittee)?_c('div',{staticClass:"d-flex justify-between mt-16 gap-8"},[_c('v-text-field',{staticClass:"w-full lg:w-1/2 xl:w-1/3 mb-4",attrs:{"label":"Search","prepend-inner-icon":"mdi-magnify","outlined":"","dense":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('StateList',{staticClass:"w-full lg:w-1/2 xl:w-1/3 mb-4",attrs:{"multiple":""},model:{value:(_vm.stateId),callback:function ($$v) {_vm.stateId=$$v},expression:"stateId"}})],1):_vm._e(),_c('v-data-table',{staticClass:"border shadow cursor-pointer",attrs:{"must-sort":"","hide-default-footer":_vm.isCommittee,"items":_vm.candidates,"loading":_vm.isPending,"server-items-length":!_vm.isPending && _vm.pagination ? _vm.pagination.total : 0,"footer-props":{ itemsPerPageOptions:[25,50,100,-1] },"headers":_vm.headers,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.goToCandidate},scopedSlots:_vm._u([{key:"item.chamber",fn:function(ref){
var campaign = ref.item.campaign;
return [_c('h6',[_vm._v(_vm._s(campaign.formattedChamber))])]}},{key:"item.stateId",fn:function(ref){
var item = ref.item;
return [_c('h6',[_vm._v(_vm._s(item.state.short))])]}},{key:"item.primaryElectionDate",fn:function(ref){
var campaign = ref.item.campaign;
return [_c('div',{staticClass:"inline-block"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('h6',_vm._g(_vm._b({},'h6',attrs,false),on),[_vm._v(_vm._s(_vm.$day(campaign.primaryElectionDate).format('MMM Do')))])]}}],null,true)},[_c('div',[_vm._v(_vm._s(_vm.$day(campaign.primaryElectionDate).format('MMM Do, YYYY')))])])],1)]}},{key:"item.generalElectionDate",fn:function(ref){
var campaign = ref.item.campaign;
return [_c('div',{staticClass:"inline-block"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('h6',_vm._g(_vm._b({},'h6',attrs,false),on),[_vm._v(_vm._s(_vm.$day(campaign.generalElectionDate).format('MMM Do')))])]}}],null,true)},[_c('div',[_vm._v(_vm._s(_vm.$day(campaign.generalElectionDate).format('MMM Do, YYYY')))])])],1)]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('h6',_vm._g(_vm._b({},'h6',attrs,false),on),[_vm._v(_vm._s(item.formattedMoney))])]}}],null,true)},[_c('div',[_vm._v("Budget: "+_vm._s(_vm._f("toCurrency")(item.campaign.budget)))])])]}}])}),(_vm.isCandidateCreateEditOpen)?_c('CandidateCreateDialog',{attrs:{"isOpen":_vm.isCandidateCreateEditOpen},on:{"close":_vm.closeAndRefresh}}):_vm._e(),(_vm.isConfirmDialogOpen)?_c('ConfirmDialog',{attrs:{"title":_vm.confirmContext.title,"text":_vm.confirmContext.text,"actionText":_vm.confirmContext.actionText,"type":_vm.confirmContext.type,"isOpen":_vm.isConfirmDialogOpen},on:{"close":_vm.closeAndMaybeUpdateUser}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }