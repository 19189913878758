<script>
import { computed, ref, watch } from '@vue/composition-api';
import AddressForm from '@/components/Address.Form'
import useApiFind from '@/api/useApiFind'
import { storeToRefs } from 'pinia'
import useCandidateStore from '@/store/custom/candidate.store'
import useCampignData from '@/helpers/useCampaignData'
import useStates from '@/helpers/useStates'

export default {
  components: {
    AddressForm
  },

  props: {
    rules: {
      type: Object,
      required: true
    }
  },

  setup(props, { root }) {
    const fecPage = ref(1)
    const fecPerPage = ref(5)
    const manuallyAddCandidate = ref(false)
    const {
      Candidate,
      Organization,
      Campaign,
      FEC
    } = root.$FeathersVuex.api
    const searchValue = ref('')
    const searchValueAtRun = ref('')
    const candidates = ref([])
    const { findByStore } = useStates()
    const { findOne } = useCampignData()
    const candidateStore = useCandidateStore()
    const {
      candidateModel,
      selectedCandidate,
    } = storeToRefs(candidateStore)
    const {
      reset,
      setSelectedCandidate,
      setValues,
      updateValues
    } = candidateStore

    const fecApi = useApiFind({
      model: FEC,
      params: computed(() => {}),
      immediate: false
    })

    watch(fecPage, (newVal, oldVal) => {
      if (newVal !== oldVal) {
        runSearch()
      }
    }, { immediate: false })

    const runSearch = async () => {
      if (!searchValueAtRun.value && searchValue.value.length < 3)
        return null

      // temp cache'd for no-results search msg
      searchValueAtRun.value = searchValue.value

      const searchParams = computed(() => ({
        query: {
          $fecApiUrl: '/candidates/search',
          $filters: {
            q: searchValue.value,
            page: fecPage.value,
            per_page: fecPerPage.value
          }
        }
      }))

      try {
        candidates.value = await fecApi.findItems(searchParams)
      } catch(e) {
        root.$snackError(e)
      }
    }

    const clearSearch = () => {
      searchValue.value = '';
      searchValueAtRun.value = '';

      runSearch()
    }

    const resetPaginationAndSearch = () => {
      fecPage.value = 1
      runSearch()
    }

    const toggleCandidate = async (_fecCandidateId) => {
      const tempOrgIds = candidateModel
        .value
        .candidate
        .organizationTypeIds;

      if (selectedCandidate.value) {
        reset()
        setValues('candidate', new Candidate({ organizationTypeIds: tempOrgIds || [] }))

        if (searchValue.value)
          runSearch()
        else
          candidates.value = []
      } else {
        const selected = candidates
          .value
          .find(c => c.candidate.fecCandidateId === _fecCandidateId)

        const {
          candidate,
          campaign: {
            party,
            office_full,
            districtNumber,
          }
        } = selected

        setValues(
          'campaign',
          new Campaign({
            districtNumber,
            party: findOne('parties', party) || 'other',
            chamber: findOne('chambers', office_full),
          })
        )

        const newSelectedCandidate = new Candidate({
          ...candidate,
          organizationTypeIds: tempOrgIds || []
        })
        setValues(
          'candidate',
          newSelectedCandidate
        )
        setSelectedCandidate(newSelectedCandidate)

        // 1. possibly populate with committee ids
        const { fecCandidateId, fecCommitteeId } = candidate
        if (fecCommitteeId !== 'NoCommittee') {
          const candidateParams = computed(() => ({
            query: {
              $fecApiUrl: `/candidate/${ fecCandidateId }`
            }
          }))
          const committeeParams = computed(() => ({
            query: {
              $fecApiUrl: `/committee/${ fecCommitteeId }`
            }
          }))

          try {
            const [candidatesWithAddresses, committees] = await Promise.all([
              fecApi.findItems(candidateParams),
              fecApi.findItems(committeeParams)
            ])

            /**
             * UPDATE CANDIDATE WITH MORE DATA
             */
            const withAddress = candidatesWithAddresses[0]

            withAddress.stateId = findByStore(
              'short',
              withAddress.stateShortCode
            ).id

            updateValues(
              'candidate',
              withAddress
            )

            if (committees.length) {
              const { committee, treasurer } = committees[0];

              /**
               * ADD COMMITTEE
               */
              committee.address.stateId = findByStore(
                'short',
                committee.address.stateShortCode
              ).id

              setValues(
                'committee',
                new Organization({ isCommittee: true, ...committee })
              )

              /**
               * ADD TREASURERL
               */
              treasurer.treasurerAddress.stateId = findByStore(
                'short',
                treasurer.treasurerAddress.stateShortCode
              ).id

              updateValues(
                'candidate',
                treasurer
              )
            }
          } catch(e) {
            console.log(e, 'Unable to request candidates or committees');
          }
        }

        // 2. limit table to only the selected
        candidates.value = [selected]
      }
    }

    return {
      fecPage,
      fecPerPage,
      manuallyAddCandidate,
      searchValue,
      searchValueAtRun,
      candidates,
      candidateModel,
      selectedCandidate,
      isPending: fecApi.isPending,

      runSearch,
      clearSearch,
      resetPaginationAndSearch,
      toggleCandidate
    }
  }
}
</script>

<template lang="pug">
.relative( v-if="manuallyAddCandidate" )
  .d-flex.justify-content-between.gap-8
    v-text-field(
      outlined
      dense
      label="First Name"
      v-model="candidateModel.candidate.firstName"
      :rules="[rules.required]"
    )

    v-text-field(
      outlined
      dense
      label="Last Name"
      v-model="candidateModel.candidate.lastName"
      :rules="[rules.required]"
    )

  .d-flex.justify-content-between.gap-8
    v-text-field(
      outlined
      dense
      label="Email"
      v-model="candidateModel.candidate.email"
      :rules="[rules.email]"
    )

    v-text-field(
      outlined
      dense
      label="Phone"
      v-model="candidateModel.candidate.phone"
      :rules="[rules.isNumeric]"
    )

  AddressForm(
    :clone="candidateModel.candidate"
    :rules="rules"
    skip-validation
  )

  span.text-xs or
  .inline-block.ml-1.text-xs(
    @click="manuallyAddCandidate = false"
    class="text-blue-700 hover:text-blue-500 cursor-pointer hover:border-b border-blue-300 border-transparent"
  ) Search the FEC for Candidate

div( v-else )
  .d-flex.align-center.relative
    v-text-field(
      class="w-full"
      v-model="searchValue"
      label="Search"
      prepend-inner-icon="mdi-magnify"
      outlined
      :disabled="!!selectedCandidate"
      @keyup.enter="resetPaginationAndSearch()"
      dense
      loading="isPending"
      hide-details
      clearable
    )
    v-btn(
      class="absolute top-1.5 right-2"
      color="primary"
      small
      :disabled="!!selectedCandidate"
      depressed
      @click="resetPaginationAndSearch()"
    ) Search
  .d-flex.justify-between.align-center
    .mt-2.space-x-2.text-xs
      span.ml-2.text-gray-500 Min of 3 characters
      span( v-if="!(!!selectedCandidate)" ) -
      span(
        @click="manuallyAddCandidate = true"
        class="text-blue-700 hover:text-blue-500 cursor-pointer hover:border-b border-blue-300"
      ) Manually Add Candidate

  v-simple-table.border.rounded-b-none.mt-6( dense v-if="candidates.length && !isPending" )
    template( v-slot:default )
      thead
        tr
          th Office
          th( class="w-3/4" ) Name
          th State
          th.border-l
      tbody
        template( v-for="{ candidate, campaign } of candidates" )
          tr
            td( class="py-1" ) {{ campaign.office_full }}
            td( class="py-1" )
              div {{ candidate.candidateName }}
            td( class="py-1" ) {{ candidate.stateShortCode }}
            td( class="pb-2 pt-2" ).border-l
              v-btn(
                @click="toggleCandidate(candidate.fecCandidateId)"
                block
                :color="!!selectedCandidate ? 'red' : 'primary'"
                depressed
                x-small
              )
                span.text-white {{ !!selectedCandidate ? 'deselect' : 'select' }}

  .flex.justify-between.mt-2.mr-1.items-center( v-if="!isPending && candidates.length > 0 && !selectedCandidate" )
    .text-sm.text-gray-400.ml-1
      span Page: {{ fecPage }}
    .space-x-2
      v-btn(
        @click="fecPage--"
        depressed
        x-small
        outlined
        :disabled="fecPage < 2"
        color="primary"
      )
        span Prev

      v-btn(
        @click="fecPage++"
        depressed
        x-small
        outlined
        :disabled="candidates.length < fecPerPage"
        color="primary"
      )
        span Next

  div.text-center.flex.flex-column.justify-center.align-center.mt-6.border-2.rounded-lg.border-gray-200(
    v-if="isPending"
  )
    v-skeleton-loader( class="mx-auto w-full" style="max-height: 200px" type="image" )
    .text-sm.absolute Loading Candidates...

  div.text-center.flex.flex-column.justify-center.align-center.items-center.mt-6.border-2.rounded-lg.border-gray-200(
    v-if="candidates.length === 0 && !isPending && searchValueAtRun.length > 0"
    style="min-height: 100px"
  )
    div
      .text-sm No candidates found with search "{{ searchValueAtRun }}"
      .flex.gap-2.items-center.justify-center
        a.text-center( @click="resetPaginationAndSearch()" ) Reset
        div -
        a.text-center( @click="clearSearch()" ) Clear search

  .border.pa-4.rounded-b.border-t-0( v-if="selectedCandidate && !isPending" )
    .grid.grid-cols-12.gap-x-4
      .col-span-4
        .font-bold.text-xs.pt-2 Party
        div.truncate {{ candidateModel.campaign.party | capitalize }}

      .col-span-8
        .font-bold.text-xs.pt-2 Committee
        div {{ candidateModel.committee && candidateModel.committee.name ? candidateModel.committee.name : 'No Committee' }}

      template( v-if="candidateModel.candidate" )
        .col-span-4
          .font-bold.text-xs.pt-2 Treasurer
          div {{ candidateModel.candidate.fullTreasurerName }}

        .col-span-8
          .font-bold.text-xs.pt-2 District
          div {{ candidateModel.campaign.districtNumber }}
      template( v-else )
        .col-span-12
          .font-bold.text-xs.pt-2 Treasurer
          div No Treasurer
</template>
