import { chambers, supportLevels, parties } from '@/helpers/campaignData'

const useCampignData = () => {
  const data = {
    chambers,
    supportLevels,
    parties
  }

  const findOne = (type, key) => {
    const fecKey = key.toLowerCase()
    const dataType = data[type].find(d => d.fecKey === fecKey)

    return dataType ? dataType.value : ''
  }

  return {
    findOne
  }
}

export default useCampignData