import { defineStore } from 'pinia'

const useCandidateStore = defineStore('candidates', {
  state: () => {
    return {
      selected: null,
      model: {
        candidate: null,
        campaign: null,
        committee: null
      }
    }
  },
  getters: {
    candidateModel: (state) => state.model,
    selectedCandidate: (state) => state.selected
  },
  actions: {
    init({ Candidate, Campaign, Organization }) {
      this.model.candidate = new Candidate()
      this.model.campaign = new Campaign()
      this.model.committee = new Organization({ isCommittee: true })

      return this.candidateModel;
    },
    setSelectedCandidate(candidate) {
      this.selected = candidate
    },
    setValues(service, model) {
      this.model[service] = model
    },
    updateValues(service, newValues) {
      const currentValues = this.model[service]
      this.model[service] = Object.assign(currentValues, newValues)
    },
    reset() {
      this.selected = null

      for (const key of Object.keys(this.model)) {
        this.model[key] = new this.model[key].constructor({})
      }
    }
  },
})

export default useCandidateStore