<template lang="pug">
.candidate-list
  .d-flex.justify-between.align-center
    h3(
      :class="{ 'mb-12' : isCommittee }"
    ) Candidates
    div
      v-btn(
        color="primary"
        v-if="$can('create', 'candidates') && !isCommittee"
        depressed
        @click="isCandidateCreateEditOpen = true"
      ) + New Candidate

  .d-flex.justify-between.mt-16.gap-8(
    v-if="!isCommittee"
  )
    v-text-field(
      class="w-full lg:w-1/2 xl:w-1/3 mb-4"
      v-model="search"
      label="Search"
      prepend-inner-icon="mdi-magnify"
      outlined
      dense
      clearable
    )
    StateList(
      class="w-full lg:w-1/2 xl:w-1/3 mb-4"
      v-model="stateId"
      multiple
    )

  v-data-table.border.shadow.cursor-pointer(
    must-sort
    :hide-default-footer="isCommittee"
    :items="candidates"
    :loading="isPending"
    :server-items-length="!isPending && pagination ? pagination.total : 0"
    :footer-props="{ itemsPerPageOptions:[25,50,100,-1] }"
    :headers="headers"
    :options.sync="options"
    @click:row="goToCandidate"
  )
    template(
      v-slot:item.chamber="{ item: { campaign } }"
    )
      h6 {{ campaign.formattedChamber }}
    template(
      v-slot:item.stateId="{ item }"
    )
      h6 {{ item.state.short }}
    template(
      v-slot:item.primaryElectionDate="{ item: { campaign } }"
    )
      div.inline-block
        v-tooltip( top )
          template( v-slot:activator="{ on, attrs }" )
            h6(
              v-on="on"
              v-bind="attrs"
            ) {{ $day(campaign.primaryElectionDate).format('MMM Do') }}
          div {{ $day(campaign.primaryElectionDate).format('MMM Do, YYYY') }}
    template(
      v-slot:item.generalElectionDate="{ item: { campaign } }"
    )
      div.inline-block
        v-tooltip( top )
          template( v-slot:activator="{ on, attrs }" )
            h6(
              v-on="on"
              v-bind="attrs"
            ) {{ $day(campaign.generalElectionDate).format('MMM Do') }}
          div {{ $day(campaign.generalElectionDate).format('MMM Do, YYYY') }}
    template(
      v-slot:item.amount="{ item }"
    )
      v-tooltip( top )
        template( v-slot:activator="{ on, attrs }" )
          h6(
            v-on="on"
            v-bind="attrs"
          ) {{ item.formattedMoney }}
        div Budget: {{ item.campaign.budget | toCurrency }}

  CandidateCreateDialog(
    :isOpen="isCandidateCreateEditOpen"
    @close="closeAndRefresh"
    v-if="isCandidateCreateEditOpen"
  )

  ConfirmDialog(
    :title="confirmContext.title"
    :text="confirmContext.text"
    :actionText="confirmContext.actionText"
    :type="confirmContext.type"
    :isOpen="isConfirmDialogOpen"
    @close="closeAndMaybeUpdateUser"
    v-if="isConfirmDialogOpen"
  )

</template>

<script>
import { ref, computed } from '@vue/composition-api'
import CandidateCreateDialog from '@/views/candidates/Candidate.Create.Dialog'
import ConfirmDialog from '@/components/Dialogs/Confirm.Dialog'
import { upperFirst } from 'lodash'
import useDataTableFind from '@/helpers/useDataTableFind'
import dataTableHeaders from '@/helpers/dataTableHeaders'
import StateList from '@/components/StateList.vue'

export default {
  name: 'CandidateList',

  components: {
    CandidateCreateDialog,
    ConfirmDialog,
    StateList
  },

  setup(props, { root: { $store, $router } }) {
    const { group } = $store.getters['auth/user']
    const isCommittee = group === 'committee'
    const isCandidateCreateEditOpen = ref(false)
    const isConfirmDialogOpen = ref(false)

    const goToCandidate = ({ id }) => {
      $router.push({
        name: 'candidateEdit', params: { id }
      })
    }
    const closeAndRefresh = () => {
      findCandidates()
      isCandidateCreateEditOpen.value = false;
    };

    const { headers, filterableColumns } = dataTableHeaders(
      [
        {
          text: 'FirstName',
          value: 'firstName',
          filterable: true
        },
              {
          text: 'LastName',
          value: 'lastName',
          filterable: true
        },
        {
          text: 'State',
          value: 'stateId',
          sortable: true
        },
        {
          text: 'Chamber',
          value: 'chamber',
          sortable: false
        },
        {
          text: 'District',
          value: 'campaign.districtNumber',
          sortable: false
        },
        {
          text: 'Primary',
          value: 'primaryElectionDate'
        },
        {
          text: 'General',
          value: 'generalElectionDate'
        },
        {
          text: 'Expenses',
          value: 'amount',
          align: 'end'
        }
      ],
      [],
      false
    );
    const stateId = ref([]);
    const filter = computed(() => {
      const filter = {
        $service: 'candidates',
        $eager: '[state]',
        $joinEager: '[campaign]',
        $select: ['firstName', 'lastName']
      }

      if (stateId.value.length > 0)
        filter.stateId = { $in: stateId.value }

      return filter
    })
    const {
      items: candidates,
      isPending,
      pagination,
      findItems: findCandidates,
      search,
      options
    } = useDataTableFind(
      ['aggregates', 'candidates'],
      filterableColumns,
      filter,
      { sortBy: ['firstName'] }
    )

    const date = new Date().toISOString();

    return {
      candidates,
      isPending,
      headers,
      findCandidates,
      goToCandidate,
      options,
      pagination,
      upperFirst,
      search,
      filter,
      stateId,
      isCommittee,
      date,

      isCandidateCreateEditOpen,
      isConfirmDialogOpen,
      closeAndRefresh
    }
  }
}
</script>

<style>

</style>